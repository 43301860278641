<div class="container">
  <div class="row relic-by-expac">
    <div class="col-xs-12">
      <h2>Relic Weapons by Expansion</h2>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
      <div class="panel panel-default">
        <div class="panel-body">
          <img src="../../assets/banners/ARR.jpg" alt="A Realm Reborn Logo">
        </div>
        <div class="panel-footer">
          <button class="btn btn-primary disabled">
            Coming Soon
          </button>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
      <div class="panel panel-default">
        <div class="panel-body">
          <img src="../../assets/banners/HW.jpg" alt="Heavensward Logo">
        </div>
        <div class="panel-footer">
          <button class="btn btn-primary disabled">
            Coming Soon
          </button>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
      <div class="panel panel-default">
        <div class="panel-body">
          <img src="../../assets/banners/SB.jpg" alt="Stormblood Logo">
        </div>
        <div class="panel-footer">
          <button class="btn btn-primary disabled">
            Coming Soon
          </button>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
      <div class="panel panel-default">
        <div class="panel-body">
          <img src="../../assets/banners/ShB.jpg" alt="Shadowbringers Logo">
        </div>
        <div class="panel-footer">
          <button class="btn btn-primary" [routerLink]="['ShB']">
            Shadowbringers
          </button>
        </div>
      </div>
    </div>

    <!-- <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
      <div class="panel panel-default">
        <div class="panel-body">
          <img src="../../assets/banners/EW.jpg" alt="Endwalker Logo">
        </div>
        <div class="panel-footer">
          <button class="btn btn-primary disabled">
            Coming Soon
          </button>
        </div>
      </div>
    </div> -->

  </div>
</div>
