<div class="col-xs-12 col-sm-6 col-md-4">
  <div class="media">
    <div class="media-left">
      <img src="../../assets/icons/{{icon}}.jpg" alt="{{icon}} Icon" class="media-object">
    </div>
    <div class="media-body">
      <p>
        <ng-content></ng-content>
      </p>
    </div>
  </div>
</div>
