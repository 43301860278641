<div class="col-xs-12 step">
  <div class="row">
    <div class="col-xs-12">
      <h2>{{steps[expac][step_id]?.name}}</h2>
      <p>{{steps[expac][step_id]?.intro_text}}</p>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6">
    <div class="row">
      <div class="col-xs-12 step-details">
        <h3>Acquisition</h3>
        <table class="table-bordered table-50">
          <thead>
            <tr>
              <th>What to get</th>
              <th>How to get</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of acquisition">
              <td>
                <app-item icon="{{item.icon}}" quantity="{{item.quantity}}">{{item.item_name}}</app-item>
              </td>
              <td>
                <p>{{item.method1}}</p>
                <p *ngIf="item.method2">{{item.method2}}</p>
                <p *ngIf="item.method3">{{item.method3}}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <h3>Turn in</h3>
        <p>{{steps[expac][step_id]?.turn_in}}</p>
      </div>
    </div>
    <div class="alert alert-warning" *ngIf="warning">
      {{steps[expac][step_id]?.warning}}
    </div>
  </div>

  <app-quest quest_id="{{steps[expac][step_id]?.quest_id}}" expac="{{expac}}"></app-quest>

</div>
