import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-poetics-vendors',
  templateUrl: './poetics-vendors.component.html',
  styleUrls: ['./poetics-vendors.component.css']
})
export class PoeticsVendorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
