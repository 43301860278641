<div class="hero-image hero-shb">
  <div class="hero-text">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <img class="hero-banner-img" src="../../../assets/banners/resistance-banner.jpg" alt="">
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-sm-8 right hero-textbox">
          <h2>Before you Begin...</h2>
          <p>Before you can begin the grind for your Resistance Weapons, you must complete the following quests for the
            Main Scenario Questline and Side Questline.</p>
          <p>Main Scenario Quest: "Shadowbringers"</p>
          <p>Side Quest: "The City of Lost Angels"</p>
          <div class="alert alert-warning">As you progress through each step of a relic weapon, make sure to have a
            spare weapon to swap to when you upgrade the relic weapon each time.</div>
        </div>
      </div>
    </div>
  </div>
</div>
<br>
<!-- main body after hero image -->
<div class="container">
  <div class="row">

    <app-relic-step step_id="0" expac="shb"></app-relic-step>

    <app-relic-step step_id="1" expac="shb"></app-relic-step>

    <app-relic-step step_id="2" expac="shb"></app-relic-step>

    <div class="col-xs-12">
        <br><br><br>
      <div class="alert-danger centered">PAST THIS POINT IS OLD</div>
    </div>

    <div class="col-xs-12">
      <!-- Step 1 - Resistance Weapons -->
      <h2 id="step1">Resistance Weapons (iLvl 485)</h2>
      <button class="btn btn-primary btn-toggle" (click)="onToggleDisplay(1)">Show/Hide</button>
      <br><br>
      <div *ngIf="step1Show">

        <p>The first time you reach this part of the questline, you will take on the quest "Fire in the Forge" which
          only
          occurs
          once. This quest gives you the materials you need for your first Resistance Weapon for free. After you have
          completed
          this quest, the quest "Resistance is (Not) Futile" becomes available as a repeatable quest for other
          Resistance
          Weapons.</p>
        <div class="row">
          <!-- Fire in the Forge Quest -->
          <div class="col-xs-12 col-sm-6">
            <div class="panel panel-default panel-quest">
              <div class="panel-heading">
                <p>Fire in the Forge</p>
              </div>
              <div class="panel-body">
                <img class="quest-banner" src="../../assets/banners/Resistance1.jpg" alt="">
                <div class="alert alert-warning">
                  This step only happens once! All additional weapons have to be done through the quest "Resistance is
                  (Not)
                  Futile"
                </div>
                <br>

                <div class="table-50">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Quest Giver</th>
                        <td>Gerolt</td>
                      </tr>
                      <tr>
                        <th>Location</th>
                        <td>Gangos (x:6.3, y:5.0)</td>
                      </tr>
                      <tr>
                        <th>Previous Quests</th>
                        <td>
                          <p>Hail to the Queen</p>
                          <p>Path to the Past</p>
                          <p>The Bozja Incident</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h3>Acquisition</h3>
                <div class="table-50">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>What to get</th>
                        <th>How to get</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <app-item icon="scalepowder" quantity="1">Thavnairian Scalepowder</app-item>
                        </td>
                        <td>
                          <p>Given for free through quest.</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h3>Turn in</h3>
                <p>Deliver 1 Thavnairian Scalepowder to Gerolt in Gangos.</p>

              </div>
            </div>
          </div>
          <!-- Resistance is (Not) Futile Quest -->
          <div class="col-xs-12 col-sm-6">
            <div class="panel panel-default panel-quest">
              <div class="panel-heading">
                <p>Resistance is (Not) Futile</p>
              </div>
              <div class="panel-body">
                <img class="quest-banner" src="../../assets/banners/Resistance1.jpg" alt="">
                <br>

                <div class="table-50">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Quest Giver</th>
                        <td>Zlatan</td>
                      </tr>
                      <tr>
                        <th>Location</th>
                        <td>Gangos (x:6.3, y:5.1)</td>
                      </tr>
                      <tr>
                        <th>Previous Quest</th>
                        <td>
                          <p>Fire in the Forge</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h3>Acquisition</h3>
                <div class="table-50">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>What to get</th>
                        <th>How to get</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <app-item icon="scalepowder" quantity="4">Thavnairian Scalepowder</app-item>
                        </td>
                        <td>
                          <p>Purchased for 1000 Allagan Tomestones of Poetics (250 each).</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p>Thavnairian Scalepowder can be obtained from the following vendors:</p>

                <!-- Vendors -->
                <ul class="list-group">
                  <li class="list-group-item">Auriana - Mor Dhona (x:22.7, y:6.6)</li>
                  <li class="list-group-item">Hismena - Idyllshire (x:5.7, y:5.2)</li>
                  <li class="list-group-item">Rowena's Representative - Limsa Lominsa Lower Decks (x:9.0, y:11.1)</li>
                  <li class="list-group-item">Rowena's Representative - Ul'dah Steps of Nald (x:9.0, y:8.3)</li>
                  <li class="list-group-item">Rowena's Representative - New Gridania (x:11.9, y:12.3)</li>
                  <li class="list-group-item">Rowena's Representative - Foundation (x:10.5, y:11.8)</li>
                  <li class="list-group-item">Rowena's Representative - Kugane (x:12.2, y:10.8)</li>
                </ul>

                <h3>Turn in</h3>
                <p>Deliver 4 Thavnairian Scalepowder to Zlatan in Gangos.</p>

              </div>
            </div>
          </div>
          <!-- Resistance Weapons iLvl 485 List -->
          <div class="col-xs-12">
            <div class="panel panel-default">
              <div class="panel-heading">Resistance Weapons (iLvl 485)</div>
              <div class="panel-body">
                <div class="row">
                  <app-weapon icon="Honorbound">Honorbound<br> (PLD)</app-weapon>
                  <app-weapon icon="Tenacity">Tenacity<br> (PLD)</app-weapon>
                  <app-weapon icon="Skullrender">Skullrender<br> (WAR)</app-weapon>
                  <app-weapon icon="Woeborn">Woeborn<br> (DRK)</app-weapon>
                  <app-weapon icon="Crownsblade">Crownsblade<br> (GNB)</app-weapon>
                  <app-weapon icon="Ingrimm">Ingrimm<br> (WHM)</app-weapon>
                  <app-weapon icon="Akademos">Akademos<br> (SCH)</app-weapon>
                  <app-weapon icon="Solstice">Solstice<br> (AST)</app-weapon>
                  <app-weapon icon="Samsara">Samsara<br> (MNK)</app-weapon>
                  <app-weapon icon="Dreizack">Dreizack<br> (DRG)</app-weapon>
                  <app-weapon icon="Honeshirazu">Honeshirazu<br> (NIN)</app-weapon>
                  <app-weapon icon="Hoshikiri">Hoshikiri<br> (SAM)</app-weapon>
                  <app-weapon icon="Brilliance">Brilliance<br> (BRD)</app-weapon>
                  <app-weapon icon="Lawman">Lawman<br> (MCH)</app-weapon>
                  <app-weapon icon="Enchufla">Enchufla<br> (DNC)</app-weapon>
                  <app-weapon icon="Soulscourge">Soulscourge<br> (BLM)</app-weapon>
                  <app-weapon icon="Espiritus">Espiritus<br> (SMN)</app-weapon>
                  <app-weapon icon="Talekeeper">Talekeeper<br> (RDM)</app-weapon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>

      <!-- Step 2 - Augmented Resistance Weapons -->
      <h2 id="step2">Augmented Resistance Weapons (iLvl 500)</h2>
      <button class="btn btn-primary btn-toggle" (click)="onToggleDisplay(2)">Show/Hide</button>
      <br><br>
      <div *ngIf="step2Show">
        <div class="row">
          <!-- For Want of a Memory Quest -->
          <div class="col-xs-12">
            <div class="panel panel-default panel-quest">
              <div class="panel-heading">
                <p>For Want of a Memory</p>
              </div>
              <div class="panel-body">
                <img class="quest-banner" src="../../assets/banners/Resistance2.jpg" alt="">
                <br>

                <div class="table-50">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Quest Giver</th>
                        <td>Zlatan</td>
                      </tr>
                      <tr>
                        <th>Location</th>
                        <td>Gangos (x:6.1, y:4.9)</td>
                      </tr>
                      <tr>
                        <th>Previous Quests</th>
                        <td>
                          <p>Vows of Virtue, Deeds of Cruelty</p>
                          <p>Where Eagles Nest</p>
                          <p>A Sober Proposal</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h3>Acquisition</h3>
                <div class="table-50">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>What to get</th>
                        <th>How to get</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <app-item icon="Tortured" quantity="20">Tortured Memory of the Dying</app-item>
                        </td>
                        <td>
                          <p>Achieving Gold for FATEs in Coerthas Western Highlands and Sea of Clouds</p>
                          <p>Random drop from Skirmishes, Critical Engagements, and enemies in zone 1 of The Bozjan
                            Southern
                            Front</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <app-item icon="Sorrowful" quantity="20">Sorrowful Memory of the Dying</app-item>
                        </td>
                        <td>
                          <p>Achieving Gold for FATEs in Dravanian Forelands and Churning Mists</p>
                          <p>Random drop from Skirmishes, Critical Engagements, and enemies in zone 2 of The Bozjan
                            Southern
                            Front</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <app-item icon="Harrowing" quantity="20">Harrowing Memory of the Dying</app-item>
                        </td>
                        <td>
                          <p>Achieving Gold for FATEs in Dravanian Hinterlands and Azys Lla</p>
                          <p>Random drop from Skirmishes, Critical Engagements, and enemies in zone 3 of The Bozjan
                            Southern
                            Front</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h3>Turn in</h3>
                <p>With your Resistance Weapon in your inventory, deliver the 60 Memories of the Dying to Zlatan in
                  Gangos.
                </p>

              </div>
            </div>
          </div>
          <!-- Augmented Resistance Weapons iLvl 500 List -->
          <div class="col-xs-12">
            <div class="panel panel-default">
              <div class="panel-heading">Augmented Resistance Weapons (iLvl 500)</div>
              <div class="panel-body">
                <div class="row">
                  <app-weapon icon="Honorbound">Augmented Honorbound<br> (PLD)</app-weapon>
                  <app-weapon icon="Tenacity">Augmented Tenacity<br> (PLD)</app-weapon>
                  <app-weapon icon="Skullrender">Augmented Skullrender<br> (WAR)</app-weapon>
                  <app-weapon icon="Woeborn">Augmented Woeborn<br> (DRK)</app-weapon>
                  <app-weapon icon="Crownsblade">Augmented Crownsblade<br> (GNB)</app-weapon>
                  <app-weapon icon="Ingrimm">Augmented Ingrimm<br> (WHM)</app-weapon>
                  <app-weapon icon="Akademos">Augmented Akademos<br> (SCH)</app-weapon>
                  <app-weapon icon="Solstice">Augmented Solstice<br> (AST)</app-weapon>
                  <app-weapon icon="Samsara">Augmented Samsara<br> (MNK)</app-weapon>
                  <app-weapon icon="Dreizack">Augmented Dreizack<br> (DRG)</app-weapon>
                  <app-weapon icon="Honeshirazu">Augmented Honeshirazu<br> (NIN)</app-weapon>
                  <app-weapon icon="Hoshikiri">Augmented Hoshikiri<br> (SAM)</app-weapon>
                  <app-weapon icon="Brilliance">Augmented Brilliance<br> (BRD)</app-weapon>
                  <app-weapon icon="Lawman">Augmented Lawman<br> (MCH)</app-weapon>
                  <app-weapon icon="Enchufla">Augmented Enchufla<br> (DNC)</app-weapon>
                  <app-weapon icon="Soulscourge">Augmented Soulscourge<br> (BLM)</app-weapon>
                  <app-weapon icon="Espiritus">Augmented Espiritus<br> (SMN)</app-weapon>
                  <app-weapon icon="Talekeeper">Augmented Talekeeper<br> (RDM)</app-weapon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>

      <!-- Step 3 - Recollection Weapons -->
      <h2 id="step3">Recollection Weapons (iLvl 500)</h2>
      <button class="btn btn-primary btn-toggle" (click)="onToggleDisplay(3)">Show/Hide</button>
      <br><br>
      <div *ngIf="step3Show">
        <div class="row">
          <!-- The Will to Resist Quest -->
          <div class="col-xs-12">
            <div class="panel panel-default panel-quest">
              <div class="panel-heading">
                <p>The Will to Resist</p>
              </div>
              <div class="panel-body">
                <img class="quest-banner" src="../../assets/banners/Resistance3.jpg" alt="">
                <br>

                <div class="table-50">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Quest Giver</th>
                        <td>Zlatan</td>
                      </tr>
                      <tr>
                        <th>Location</th>
                        <td>Gangos (x:6.1, y:4.9)</td>
                      </tr>
                      <tr>
                        <th>Previous Quest</th>
                        <td>
                          <p>For Want of a Memory</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h3>Acquisition</h3>
                <div class="table-50">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>What to get</th>
                        <th>How to get</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <app-item icon="Bitter" quantity="6">Bitter Memory of the Dying</app-item>
                        </td>
                        <td>
                          <p>Level 60 Dungeons (Level-Synced)</p>
                          <p>Duty Roulette: Leveling (Once a day)</p>
                          <p>Random drops from enemies in The Bozjan Southern Front</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h3>Turn in</h3>
                <p>With your Augmented Resistance Weapon in your inventory, deliver the 6 Bitter Memories of the Dying
                  to
                  Zlatan
                  in Gangos.</p>
              </div>
            </div>
          </div>
          <!-- Recollection Weapons iLvl 500 List -->
          <div class="col-xs-12">
            <div class="panel panel-default">
              <div class="panel-heading">Recollection Weapons (iLvl 500)</div>
              <div class="panel-body">
                <div class="row">
                  <app-weapon icon="Honorbound">Honorbound Recollection<br> (PLD)</app-weapon>
                  <app-weapon icon="Tenacity">Tenacity Recollection<br> (PLD)</app-weapon>
                  <app-weapon icon="Skullrender">Skullrender Recollection<br> (WAR)</app-weapon>
                  <app-weapon icon="Woeborn">Woeborn Recollection<br> (DRK)</app-weapon>
                  <app-weapon icon="Crownsblade">Crownsblade Recollection<br> (GNB)</app-weapon>
                  <app-weapon icon="Ingrimm">Ingrimm Recollection<br> (WHM)</app-weapon>
                  <app-weapon icon="Akademos">Akademos Recollection<br> (SCH)</app-weapon>
                  <app-weapon icon="Solstice">Solstice Recollection<br> (AST)</app-weapon>
                  <app-weapon icon="Samsara">Samsara Recollection<br> (MNK)</app-weapon>
                  <app-weapon icon="Dreizack">Dreizack Recollection<br> (DRG)</app-weapon>
                  <app-weapon icon="Honeshirazu">Honeshirazu Recollection<br> (NIN)</app-weapon>
                  <app-weapon icon="Hoshikiri">Hoshikiri Recollection<br> (SAM)</app-weapon>
                  <app-weapon icon="Brilliance">Brilliance Recollection<br> (BRD)</app-weapon>
                  <app-weapon icon="Lawman">Lawman Recollection<br> (MCH)</app-weapon>
                  <app-weapon icon="Enchufla">Enchufla Recollection<br> (DNC)</app-weapon>
                  <app-weapon icon="Soulscourge">Soulscourge Recollection<br> (BLM)</app-weapon>
                  <app-weapon icon="Espiritus">Espiritus Recollection<br> (SMN)</app-weapon>
                  <app-weapon icon="Talekeeper">Talekeeper Recollection<br> (RDM)</app-weapon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>

      <!-- Step 4 - Law's Order Weapons -->
      <h2 id="step4">Law's Order Weapons (iLvl 510)</h2>
      <button class="btn btn-primary btn-toggle" (click)="onToggleDisplay(4)">Show/Hide</button>
      <br><br>
      <div *ngIf="step4Show">
        <div class="row">
          <!-- Change of Arms Quest -->
          <div class="col-xs-12">
            <div class="panel panel-default panel-quest">
              <div class="panel-heading">
                <p>Change of Arms</p>
              </div>
              <div class="panel-body">
                <img class="quest-banner" src="../../assets/banners/Resistance4.jpg" alt="">
                <br>

                <div class="table-50">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Quest Giver</th>
                        <td>Zlatan</td>
                      </tr>
                      <tr>
                        <th>Location</th>
                        <td>Gangos (x:6.1, y:4.9)</td>
                      </tr>
                      <tr>
                        <th>Previous Quests</th>
                        <td>
                          <p>The Will to Resist</p>
                          <p>A Sign of What's to Come</p>
                          <p>Fit for a Queen</p>
                          <p>In the Queen's Image</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h3>Acquisition</h3>
                <div class="table-50">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>What to get</th>
                        <th>How to get</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <app-item icon="Loathsome" quantity="15">Loathsome Memories of the Dying</app-item>
                        </td>
                        <td>
                          <p>5 guaranteed from completing Castrum Lacus Litore.</p>
                          <p>1 guaranteed from completing any of the alliance raids from the "Crystal Tower" series
                            (level-synced).</p>
                          <p>1 randomly from completing critical engagements in The Bozjan Southern Front.</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h3>Turn in</h3>
                <p>Deliver the 15 Loathsome Memories of the Dying to Zlatan in Gangos.</p>

              </div>
            </div>
          </div>

          <!-- Law's Order Weapons - iLvl 510 List -->
          <div class="col-xs-12">
            <div class="panel panel-default">
              <div class="panel-heading">Law's Order Weapons (iLvl 510)</div>
              <div class="panel-body">
                <div class="row">
                  <app-weapon icon="Law's Order Bastard Sword">Law's Order Bastard Sword<br> (PLD)</app-weapon>
                  <app-weapon icon="Law's Order Kite Shield">Law's Order Kite Shield<br> (PLD)</app-weapon>
                  <app-weapon icon="Law's Order Labrys">Law's Order Labrys<br> (WAR)</app-weapon>
                  <app-weapon icon="Law's Order Zweihander">Law's Order Zweihander<br> (DRK)</app-weapon>
                  <app-weapon icon="Law's Order Manatrigger">Law's Order Manatrigger<br> (GNB)</app-weapon>
                  <app-weapon icon="Law's Order Cane">Law's Order Cane<br> (WHM)</app-weapon>
                  <app-weapon icon="Law's Order Codex">Law's Order Codex<br> (SCH)</app-weapon>
                  <app-weapon icon="Law's Order Astrometer">Law's Order Astrometer<br> (AST)</app-weapon>
                  <app-weapon icon="Law's Order Knuckles">Law's Order Knuckles<br> (MNK)</app-weapon>
                  <app-weapon icon="Law's Order Spear">Law's Order Spear<br> (DRG)</app-weapon>
                  <app-weapon icon="Law's Order Knives">Law's Order Knives<br> (NIN)</app-weapon>
                  <app-weapon icon="Law's Order Samurai Blade">Law's Order Samurai Blade<br> (SAM)</app-weapon>
                  <app-weapon icon="Law's Order Composite Bow">Law's Order Composite Bow<br> (BRD)</app-weapon>
                  <app-weapon icon="Law's Order Revolver">Law's Order Revolver<br> (MCH)</app-weapon>
                  <app-weapon icon="Law's Order Chakrams">Law's Order Chakrams<br> (DNC)</app-weapon>
                  <app-weapon icon="Law's Order Rod">Law's Order Rod<br> (BLM)</app-weapon>
                  <app-weapon icon="Law's Order Index">Law's Order Index<br> (SMN)</app-weapon>
                  <app-weapon icon="Law's Order Rapier">Law's Order Rapier<br> (RDM)</app-weapon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>

      <!-- Step 5 - First One Time Grind -->
      <h2 id="step5">Law's Order Weapons One-Time Grind</h2>
      <button class="btn btn-primary btn-toggle" (click)="onToggleDisplay(5)">Show/Hide</button>
      <br><br>
      <div *ngIf="step5Show">
        <div class="row">
          <!-- The Resistance Remembers Quest -->
          <div class="col-xs-12">
            <div class="panel panel-default panel-quest">
              <div class="panel-heading">
                <p>The Resistance Remembers</p>
              </div>
              <div class="panel-body">
                <div class="alert alert-warning">
                  This quest is only required to be done one time! DO NOT try to collect more than the listed amount
                  below!
                </div>
                <img src="../../assets/banners/Resistance5.jpg" alt="" class="quest-banner">
                <br>

                <div class="table-50">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Quest Giver</th>
                        <td>Gerolt</td>
                      </tr>
                      <tr>
                        <th>Location</th>
                        <td>Gangos (x:6.2, y:5.0)</td>
                      </tr>
                      <tr>
                        <th>Previous Quest</th>
                        <td>
                          <p>Change of Arms</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h3>Acquisition</h3>
                <div class="table-50">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>What to get</th>
                        <th>How to get</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <app-item icon="Haunting" quantity="18">Haunting Memories of the Dying</app-item>
                        </td>
                        <td>
                          <p>3 guaranteed from completing any of the alliance raids from the "Shadow of Mhach" series
                            (level-synced).</p>
                          <p>1 randomly from FATEs in Gyr Abania zones.</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <app-item icon="Vexatious" quantity="18">Vexatious Memories of the Dying</app-item>
                        </td>
                        <td>
                          <p>3 guaranteed from completing any of the alliance raids from the "Return to Ivalice" series
                            (level-synced).</p>
                          <p>1 randomly from FATEs in Far East zones.</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h3>Turn in</h3>
                <p>Deliver 18 Haunting Memories of the Dying and 18 Vexatious Memories of the Dying to the Allagan Node
                  in
                  Gangos.</p>

              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>

      <!-- Step 6 - Augmented Law's Order Weapons -->
      <h2 id="step6">Augmented Law's Order Weapons (iLvl 515)</h2>
      <button class="btn btn-primary btn-toggle" (click)="onToggleDisplay(6)">Show/Hide</button>
      <br><br>
      <div *ngIf="step6Show">
        <div class="row">
          <!-- A New Path of Resistance Quest -->
          <div class="col-xs-12">
            <div class="panel panel-default panel-quest">
              <div class="panel-heading">
                <p>A New Path of Resistance</p>
              </div>
              <div class="panel-body">
                <img src="../../assets/banners/Resistance6.jpg" alt="" class="quest-banner">
                <br>

                <div class="table-50">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Quest Giver</th>
                        <td>Zlatan</td>
                      </tr>
                      <tr>
                        <th>Location</th>
                        <td>Gangos (x:6.1, y:4.9)</td>
                      </tr>
                      <tr>
                        <th>Previous Quest</th>
                        <td>
                          <p>The Resistance Remembers</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h3>Acquisition</h3>
                <div class="table-50">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>What to get</th>
                        <th>How to get</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <app-item icon="Timeworn" quantity="15">Timeworn Artifact</app-item>
                        </td>
                        <td>
                          <p>3 guaranteed from completing Delubrum Reginae.</p>
                          <p>1 randomly from completing a set of 10 floors in Palace of the Dead. Tip: It has been found
                            that
                            the higher the set of floors, the higher the chance for a drop.</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h3>Turn in</h3>
                <p>Deliver the 15 Timeworn Artifacts to Zlatan in Gangos.</p>

                <div class="alert alert-warning">
                  As you turn in your 15 Timeworn Artifacts you will be asked to allocate the stats of your weapon.
                  These
                  stats
                  can be whatever you want them to be as long as you use the number required. If you aren't sure how to
                  allocate
                  your stats refer to the guide below. If you already know what stats you want please ignore the
                  following.
                  <br><br>
                  If you need to reallocate your stats at any point you can complete a small quest called "Forged Anew"
                  listed
                  at the end of this guide.
                </div>

                <h3>Stat Allocation Suggestions</h3>
                <p>If you aren't sure how to allocate your stats and want a good rule of thumb to follow, then refer to
                  the
                  following info based on the role that your weapon is for:</p>
                <br>

                <h4>DPS Jobs</h4>
                <p>Generally your stat priority should follow the following outline:<br>
                  Critical Hit > Direct Hit > Determination > Skill Speed/Spell Speed</p>
                <p>Keep in mind that different jobs may want different stats priorities. For example, if you play Black
                  Mage,
                  you may want to take Spell Speed in the place of Critical Hit depending on your playstyle.</p>
                <br>

                <h4>Healer Jobs</h4>
                <p>Generally your stat priority should follow the following outline:<br>
                  Critical Hit > Determination > Piety=Spell Speed</p>
                <p>Keep in mind that your Piety and Spell Speed allocations are based on preference. If you want to go
                  faster,
                  choose Spell Speed. If you need more mana regeneration, choose Piety.</p>
                <br>

                <h4>Tank Jobs</h4>
                <p>Generally your stat priority should follow the following outline:<br>
                  Critical Hit > Determination > Tenacity=Skill Speed</p>
                <p>Keep in mind that your Tenacity and Skill Speed allocations are based on preference. If you want to
                  go
                  faster, choose Skill Speed. If you want a general stat increase, choose Tenacity. Tenacity is a sort
                  of
                  strange stat because of how it scales so most people prefer to use Direct Hit as a stat. But because
                  the
                  Tank
                  weapons don't allow you to allocate Direct Hit as a stat you have to choose something else.</p>

              </div>
            </div>
          </div>

          <!-- Augmented Law's Order Weapons - iLvl 510 List -->
          <div class="col-xs-12">
            <div class="panel panel-default">
              <div class="panel-heading">Augmented Law's Order Weapons (iLvl 515)</div>
              <div class="panel-body">
                <div class="row">
                  <app-weapon icon="Law's Order Bastard Sword">Augmented Law's Order Bastard Sword<br> (PLD)
                  </app-weapon>
                  <app-weapon icon="Law's Order Kite Shield">Augmented Law's Order Kite Shield<br> (PLD)</app-weapon>
                  <app-weapon icon="Law's Order Labrys">Augmented Law's Order Labrys<br> (WAR)</app-weapon>
                  <app-weapon icon="Law's Order Zweihander">Augmented Law's Order Zweihander<br> (DRK)</app-weapon>
                  <app-weapon icon="Law's Order Manatrigger">Augmented Law's Order Manatrigger<br> (GNB)</app-weapon>
                  <app-weapon icon="Law's Order Cane">Augmented Law's Order Cane<br> (WHM)</app-weapon>
                  <app-weapon icon="Law's Order Codex">Augmented Law's Order Codex<br> (SCH)</app-weapon>
                  <app-weapon icon="Law's Order Astrometer">Augmented Law's Order Astrometer<br> (AST)</app-weapon>
                  <app-weapon icon="Law's Order Knuckles">Augmented Law's Order Knuckles<br> (MNK)</app-weapon>
                  <app-weapon icon="Law's Order Spear">Augmented Law's Order Spear<br> (DRG)</app-weapon>
                  <app-weapon icon="Law's Order Knives">Augmented Law's Order Knives<br> (NIN)</app-weapon>
                  <app-weapon icon="Law's Order Samurai Blade">Augmented Law's Order Samurai Blade<br> (SAM)
                  </app-weapon>
                  <app-weapon icon="Law's Order Composite Bow">Augmented Law's Order Composite Bow<br> (BRD)
                  </app-weapon>
                  <app-weapon icon="Law's Order Revolver">Augmented Law's Order Revolver<br> (MCH)</app-weapon>
                  <app-weapon icon="Law's Order Chakrams">Augmented Law's Order Chakrams<br> (DNC)</app-weapon>
                  <app-weapon icon="Law's Order Rod">Augmented Law's Order Rod<br> (BLM)</app-weapon>
                  <app-weapon icon="Law's Order Index">Augmented Law's Order Index<br> (SMN)</app-weapon>
                  <app-weapon icon="Law's Order Rapier">Augmented Law's Order Rapier<br> (RDM)</app-weapon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>

      <!-- Step 7 - Second One Time Grind -->
      <h2 id="step7">Blade's Weapons One-Time Grind</h2>
      <button class="btn btn-primary btn-toggle" (click)="onToggleDisplay(2)">Show/Hide</button>
      <br><br>
      <div *ngIf="step2Show">
        <div class="row">
          <!-- What Dreams Are Made Of Quest -->
          <div class="col-xs-12">
            <div class="panel panel-default panel-quest">
              <div class="panel-heading">
                <p>What Dreams Are Made Of</p>
              </div>
              <div class="panel-body">
                <div class="alert alert-warning">
                  This quest is only required to be done one time! DO NOT try to collect more than the listed amount
                  below!
                </div>
                <img src="../../assets/banners/Resistance7.jpg" alt="" class="quest-banner">
                <br>

                <div class="table-50">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Quest Giver</th>
                        <td>Gerolt</td>
                      </tr>
                      <tr>
                        <th>Location</th>
                        <td>Gangos (x:6.2, y:5.0)</td>
                      </tr>
                      <tr>
                        <th>Previous Quest</th>
                        <td>
                          <p>A New Path of Resistance</p>
                          <p>A New Playing Field</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h3>Before You Continue!</h3>
                <p>This quest is only an intro to the following quests that you must complete. These 3 quests are only
                  required to be completed once. You can pick them up one at a time or all at once. If you plan to grind
                  these
                  quests while in Zadnor, you may want to pick up all three so you don't miss out on rewards. Please see
                  details below that explain why this may be better for you.</p>

                <hr>

                <!-- Spare Parts Quest -->
                <h3 id="spareParts">
                  <p>Spare Parts</p>
                </h3>
                <div class="table-50">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Quest Giver</th>
                        <td>Zlatan</td>
                      </tr>
                      <tr>
                        <th>Location</th>
                        <td>Gangos (x:6.1, y:4.9)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h4>Acquisition</h4>
                <div class="table-50">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>What to get</th>
                        <th>How to get</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <app-item icon="Axle" quantity="30">Compact Axle</app-item>
                        </td>
                        <td>
                          <p>1 guaranteed from completing Skirmishes in Zadnor Southern Plateau (Zone 1) with gold
                            rating.
                          </p>
                          <p>1 guaranteed from completing any of the following normal Alexander raids (level-synced
                            only):
                          </p>
                          <ul>
                            <li>Alexander - The Fist of the Father</li>
                            <li>Alexander - The Cuff of the Father</li>
                            <li>Alexander - The Fist of the Son</li>
                            <li>Alexander - The Cuff of the Son</li>
                            <li>Alexander - The Eyes of the Creator</li>
                            <li>Alexander - The Breath of the Creator</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <app-item icon="Spring" quantity="30">Compact Spring</app-item>
                        </td>
                        <td>
                          <p>2 guaranteed from completing Critical Engagements in Zadnor Southern Plateau (Zone 1).</p>
                          <p>1 guaranteed from completing any of the following normal Alexander raids (level-synced
                            only):
                          </p>
                          <ul>
                            <li>Alexander - The Arm of the Father</li>
                            <li>Alexander - The Burden of the Father</li>
                            <li>Alexander - The Arm of the Son</li>
                            <li>Alexander - The Burden of the Son</li>
                            <li>Alexander - The Heart of the Creator</li>
                            <li>Alexander - The Soul of the Creator</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr>

                <!-- Tell Me a Story Quest -->
                <h3 id="tellMeAStory">
                  <p>Tell Me a Story</p>
                </h3>
                <div class="table-50">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Quest Giver</th>
                        <td>Zlatan</td>
                      </tr>
                      <tr>
                        <th>Location</th>
                        <td>Gangos (x:6.1, y:4.9)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h4>Acquisition</h4>
                <div class="table-50">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>What to get</th>
                        <th>How to get</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <app-item icon="Realm" quantity="30">A Day in the Life: Battles for the Realm</app-item>
                        </td>
                        <td>
                          <p>1 guaranteed from completing Skirmishes in Zadnor Western Plateau (Zone 2) with gold
                            rating.
                          </p>
                          <p>1 guaranteed from completing any of the following normal Omega raids (level-synced only):
                          </p>
                          <ul>
                            <li>Deltascape V1.0</li>
                            <li>Deltascape V2.0</li>
                            <li>Sigmascape V1.0</li>
                            <li>Sigmascape V2.0</li>
                            <li>Alphascape V1.0</li>
                            <li>Alphascape V2.0</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <app-item icon="Rift" quantity="30">A Day in the Life: Battles for the Rift</app-item>
                        </td>
                        <td>
                          <p>2 guaranteed from completing Critical Engagements in Zadnor Western Plateau (Zone 2).</p>
                          <p>1 guaranteed from completing any of the following normal Omega raids (level-synced only):
                          </p>
                          <ul>
                            <li>Deltascape V3.0</li>
                            <li>Deltascape V4.0</li>
                            <li>Sigmascape V3.0</li>
                            <li>Sigmascape V4.0</li>
                            <li>Alphascape V3.0</li>
                            <li>Alphascape V4.0</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr>

                <!-- A Fond Memory Quest -->
                <h3 id="aFondMemory">
                  <p>A Fond Memory</p>
                </h3>
                <div class="table-50">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Quest Giver</th>
                        <td>Zlatan</td>
                      </tr>
                      <tr>
                        <th>Location</th>
                        <td>Gangos (x:6.1, y:4.9)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h4>Acquisition</h4>
                <div class="table-50">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>What to get</th>
                        <th>How to get</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <app-item icon="Bleak" quantity="30">Bleak Memories of the Dying</app-item>
                        </td>
                        <td>
                          <p>1 guaranteed from completing Skirmishes in Zadnor Northern Plateau (Zone 3) with gold
                            rating.
                          </p>
                          <p>1 guaranteed from completing any of the following normal Eden raids (level-synced only):
                          </p>
                          <ul>
                            <li>Eden's Gate: Resurrection</li>
                            <li>Eden's Gate: Descent</li>
                            <li>Eden's Verse: Fulmination</li>
                            <li>Eden's Verse: Furor</li>
                            <li>Eden's Promise: Umbra</li>
                            <li>Eden's Promise: Litany</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <app-item icon="Lurid" quantity="30">Lurid Memories of the Dying</app-item>
                        </td>
                        <td>
                          <p>2 guaranteed from completing Critical Engagements in Zadnor Northern Plateau (Zone 3).</p>
                          <p>1 guaranteed from completing any of the following normal Eden raids (level-synced only):
                          </p>
                          <ul>
                            <li>Eden's Gate: Inundation</li>
                            <li>Eden's Gate: Sepulture</li>
                            <li>Eden's Verse: Iconoclasm</li>
                            <li>Eden's Verse: Refulgence</li>
                            <li>Eden's Promise: Anamorphosis</li>
                            <li>Eden's Promise: Eternity</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h4>Turn in</h4>
                <p>All of the items can be turned into Zlatan in Gangos. Each quest can be turned in seperately as you
                  complete them.</p>

              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>

      <!-- Step 8 - Blade's Weapons -->
      <h2 id="step8">Blade's Weapons (iLvl 535)</h2>
      <button class="btn btn-primary btn-toggle" (click)="onToggleDisplay(8)">Show/Hide</button>
      <br><br>
      <div *ngIf="step8Show">
        <div class="row">
          <!-- Irresistible Quest -->
          <div class="col-xs-12">
            <div class="panel panel-default panel-quest">
              <div class="panel-heading">
                <p>Irresistible</p>
              </div>
              <div class="panel-body">
                <img src="../../assets/banners/Resistance8.jpg" alt="" class="quest-banner">
                <br>

                <div class="table-50">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Quest Giver</th>
                        <td>Zlatan</td>
                      </tr>
                      <tr>
                        <th>Location</th>
                        <td>Gangos (x:6.1, y:4.9)</td>
                      </tr>
                      <tr>
                        <th>Previous Quest</th>
                        <td>
                          <p>A Done Deal</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h3>Acquisition</h3>
                <div class="table-50">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>What to get</th>
                        <th>How to get</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <app-item icon="RawEmotion" quantity="15">Raw Emotion</app-item>
                        </td>
                        <td>
                          <p>3 guaranteed from completing Dalriada.</p>
                          <p>2 guaranteed from completing Delubrum Reginae.</p>
                          <p>1 guaranteed from completing any level 70 dungeon (level-sync only).</p>
                          <p>1 randomly from completing a set of 10 floors in Heaven-On-High. Tip: It has been found
                            that
                            the higher the set of floors, the higher the chance for a drop.</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h3>Turn in</h3>
                <p>Deliver the 15 Raw Emotions to Zlatan in Gangos.</p>

                <div class="alert alert-warning">
                  As you turn in your 15 Raw Emotions you will be asked to allocate the stats of your weapon. These
                  stats
                  can be whatever you want them to be as long as you use the number required. If you aren't sure how to
                  allocate
                  your stats refer to the guide below. If you already know what stats you want please ignore the
                  following.
                  <br><br>
                  If you need to reallocate your stats at any point you can complete a small quest called "Forged Anew"
                  listed
                  at the end of this guide.
                </div>

                <h3>Stat Allocation Suggestions</h3>
                <p>If you aren't sure how to allocate your stats and want a good rule of thumb to follow, then refer to
                  the
                  following info based on the role that your weapon is for:</p>
                <br>

                <h4>DPS Jobs</h4>
                <p>Generally your stat priority should follow the following outline:<br>
                  Critical Hit > Direct Hit > Determination > Skill Speed/Spell Speed</p>
                <p>Keep in mind that different jobs may want different stats priorities. For example, if you play Black
                  Mage,
                  you may want to take Spell Speed in the place of Critical Hit depending on your playstyle.</p>
                <br>

                <h4>Healer Jobs</h4>
                <p>Generally your stat priority should follow the following outline:<br>
                  Critical Hit > Determination > Piety=Spell Speed</p>
                <p>Keep in mind that your Piety and Spell Speed allocations are based on preference. If you want to go
                  faster,
                  choose Spell Speed. If you need more mana regeneration, choose Piety.</p>
                <br>

                <h4>Tank Jobs</h4>
                <p>Generally your stat priority should follow the following outline:<br>
                  Critical Hit > Determination > Tenacity=Skill Speed</p>
                <p>Keep in mind that your Tenacity and Skill Speed allocations are based on preference. If you want to
                  go
                  faster, choose Skill Speed. If you want a general stat increase, choose Tenacity. Tenacity is a sort
                  of
                  strange stat because of how it scales so most people prefer to use Direct Hit as a stat. But because
                  the
                  Tank
                  weapons don't allow you to allocate Direct Hit as a stat you have to choose something else.</p>

              </div>
            </div>
          </div>

          <!-- Blade's Weapons - iLvl 535 List -->
          <div class="col-xs-12">
            <div class="panel panel-default">
              <div class="panel-heading">Blade's Weapons (iLvl 535)</div>
              <div class="panel-body">
                <div class="row">
                  <app-weapon icon="Honor">Blade's Honor<br> (PLD)</app-weapon>
                  <app-weapon icon="Fortitude">Blade's Fortitude<br> (PLD)</app-weapon>
                  <app-weapon icon="Valor">Blade's Valor<br> (WAR)</app-weapon>
                  <app-weapon icon="Justice">Blade's Justice<br> (DRK)</app-weapon>
                  <app-weapon icon="Resolve">Blade's Resolve<br> (GNB)</app-weapon>
                  <app-weapon icon="Mercy">Blade's Mercy<br> (WHM)</app-weapon>
                  <app-weapon icon="Wisdom">Blade's Wisdom<br> (SCH)</app-weapon>
                  <app-weapon icon="Providence">Blade's Providence<br> (AST)</app-weapon>
                  <app-weapon icon="Serenity">Blade's Serenity<br> (MNK)</app-weapon>
                  <app-weapon icon="Glory">Blade's Glory<br> (DRG)</app-weapon>
                  <app-weapon icon="Subtlety">Blade's Subtlety<br> (NIN)</app-weapon>
                  <app-weapon icon="Fealty">Blade's Fealty<br> (SAM)</app-weapon>
                  <app-weapon icon="Muse">Blade's Muse<br> (BRD)</app-weapon>
                  <app-weapon icon="Ingenuity">Blade's Ingenuity<br> (MCH)</app-weapon>
                  <app-weapon icon="Euphoria">Blade's Euphoria<br> (DNC)</app-weapon>
                  <app-weapon icon="Fury">Blade's Fury<br> (BLM)</app-weapon>
                  <app-weapon icon="Acumen">Blade's Acumen<br> (SMN)</app-weapon>
                  <app-weapon icon="Temperance">Blade's Temperance<br> (RDM)</app-weapon>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <hr>

      <!-- Forged Anew Quest -->
      <h2 id="step9">Forged Anew Quest (Stat Reallocation)</h2>
      <button class="btn btn-primary btn-toggle" (click)="onToggleDisplay(9)">Show/Hide</button>
      <br><br>
      <div *ngIf="step9Show">
        <p>If at any point you need to change the allocation of your weapon's stats you can do that with the Allagan
          Node
          in
          Gangos. This only applies to the Augmented Law's Order Weapons and the Blade's Weapons.</p>
        <div class="row">
          <div class="col-xs-12">
            <div class="panel panel-default panel-quest">
              <div class="panel-heading">
                <p>Forged Anew</p>
              </div>
              <div class="panel-body">

                <div class="table-50">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <th>Quest Giver</th>
                        <td>Allagan Node</td>
                      </tr>
                      <tr>
                        <th>Location</th>
                        <td>Gangos (x:5.9, y:4.8)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h3>Acquisition</h3>
                <div class="table-50">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th>What to get</th>
                        <th>How to get</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <app-item icon="Sealant" quantity="4">Aetherial Sealant</app-item>
                        </td>
                        <td>
                          <p>Purchased from either of the following vendors for 100 Allagan Tomestone of Poetics each
                            (400
                            total):</p>
                          <p>Auriana - Mor Dhona (x:22.7, y:6.6)</p>
                          <p>Hismena - Idyllshire (x:5.7, y:5.2)</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>





      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>

    </div>
  </div>
